<template lang="html">
  <div>
      <section-title title="Add new card"></section-title>
      <ValidationObserver v-slot="{ handleSubmit }" slim>
        <form @submit.prevent="handleSubmit(submitNewCard)" class="Form">
          <formly-form :form="formDetails" :model="form" :fields="fields" tag="div" class="Form__group"></formly-form>
          <button class="btn btn--sm btn--full btn--outlined">Save card</button>
        </form>
      </ValidationObserver>
    </div>
</template>

<script>
import PaymentFormMixin from '@/mixins/PaymentForm';
export default {
  name: "UpdateCreditCard",
  mixins: [PaymentFormMixin],
}
</script>
