<template lang="html">
  <ValidationObserver v-slot="{ handleSubmit }" ref="changeForm">
    <form @submit.prevent="handleSubmit(handlePasswordReset)" class="Form">
      <div class="Form__wrapper">
        <formly-form :form="form" :model="model" :fields="fields" tag="div" class="Form__group"></formly-form>
      </div>
      <button class="btn btn--outlined">Change your password</button>
   </form>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'ChangePassword',
  data: function(){
    return {
      model: {
        current_password: '',
        password: '',
        password_confirmation: '',
      },
      form: {},
      fields: [
        {
           key: 'current_password',
           type: 'input',
           rules: 'required',
           templateOptions: {
              type: 'password',
              label: 'Current Password',
              layout: 'full',
           },
        },
         {
            key: 'password',
            type: 'input',
            rules: 'required|password:@password_confirmation',
            templateOptions: {
               type: 'password',
               label: 'New Password',
               layout: 'full',
            },
         },
         {
            key: 'password_confirmation',
            type: 'input',
            rules: 'required',
            templateOptions: {
               type: 'password',
               label: 'Repeat New Password',
               layout: 'full',
            },
         }
      ]
    }
  },
  methods: {
    sanitizeForm() {
      this.model.current_password = ''
      this.model.password = ''
      this.model.password_confirmation = ''
      this.$refs.changeForm.reset()
    },

    handlePasswordReset() {
      this.$store.dispatch('UPDATE_PASSWORD', {...this.model})
      .then(() => {
        this.passwordChanged = true
        this.sanitizeForm()
      })
      .catch(() => {
        this.passwordChangeFailed = true
        this.sanitizeForm()
      })
    }
  }
}
</script>
