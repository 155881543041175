<template lang="html">
  <div class="Dashboard">
    <dashboard-top>
      <screen-title title="Settings"></screen-title>
    </dashboard-top>
    <div class="container-full">
      <tabs>
        <tab title="Account & profile details">
          <section class="section">
            <div class="section__col">
              <div class="dashboard__section">
                <section-title title="Profile information" />
                <UpdateProfile />
              </div>
              <div class="dashboard__section" v-if="userIs('player')">
                <section-title title="Additional Information" />
                <UpdatePlayerDetails></UpdatePlayerDetails>
              </div>
              <div class="dashboard__section" v-if="userIs('player')">
                <section-title title="Preferred positions" />
                <UpdatePreferredPositions></UpdatePreferredPositions>
              </div>
            </div>
            <div class="section__col section__col--bottom">
              <div class="dashboard__section">
                <section-title title="Update password" />
                <ChangePassword />
              </div>
            </div>
          </section>
        </tab>
        <tab title="Manage divisions" v-if="userIs('admin')">
          <DivisionsManagement />
        </tab>
        <tab title="Club information" v-if="userIs('admin')">
          <section class="section">
            <UpdateTeam></UpdateTeam>
          </section>
        </tab>
        <tab title="Payment methods & invoice details" v-if="userIs('admin')">
          <section class="section">
            <div class="section__col">
              <div class="dashboard__section">
                <section-title title="Payment methods" />
                <div
                  class="payment-methods"
                  v-if="methods && methods.length > 0"
                >
                  <CreditCard
                    v-for="card in methods"
                    :key="card.id"
                    :card="card"
                  />
                </div>
                <div class="payment-methods__empty" v-else>
                  <empty-message>
                    You haven't added payment methods.
                  </empty-message>
                </div>
                <button
                  class="btn btn--sm btn--outlined"
                  @click="
                    () => {
                      this.addNewCard = !this.addNewCard
                    }
                  "
                >
                  {{ newCardButtonText }}
                </button>

                <vue-toggle-slide :active="addNewCard" class="pt-24">
                  <div class="Card Card--limited">
                    <UpdateCreditCard />
                  </div>
                </vue-toggle-slide>
              </div>
            </div>
            <div class="section__col">
              <ExtraBillingInformation v-if="team"></ExtraBillingInformation>
            </div>
          </section>
        </tab>
        <tab title="Remove Account">
          <RemoveAccount></RemoveAccount>
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  import UpdateProfile from '@/components/Forms/UpdateProfile'
  import ChangePassword from '@/components/Forms/ChangePassword'
  import UpdateCreditCard from '@/components/Forms/UpdateCreditCard'
  import UpdatePreferredPositions from '@/components/Forms/UpdatePreferredPositions'
  import UpdatePlayerDetails from '@/components/Forms/UpdatePlayerDetails'
  import ExtraBillingInformation from '@/components/Forms/ExtraBillingInformation'
  import CreditCard from '@/partials/CreditCard'
  import UpdateTeam from '@/components/Forms/UpdateTeam'

  import DivisionsManagement from './partials/DivisionsManagement'
  import RemoveAccount from './partials/RemoveAccount'

  export default {
    name: 'ProfileSettings',
    components: {
      CreditCard,
      UpdateCreditCard,
      UpdateProfile,
      ExtraBillingInformation,
      UpdatePreferredPositions,
      UpdatePlayerDetails,
      ChangePassword,
      RemoveAccount,
      DivisionsManagement,
      UpdateTeam,
    },
    data() {
      return {
        addNewCard: false,
      }
    },
    mounted() {
      this.fetchPaymentMethods()
    },
    methods: {
      fetchPaymentMethods() {
        if (this.userIs('admin')) {
          this.$store.dispatch('payments/GET_PAYMENT_METHODS')
        }
      },
    },
    computed: {
      ...mapState({
        user: (state) => state.user,
        role: (state) => (state.user ? state.user.role : false),
        team: (state) => (state.user ? state.user.team : false),
        methods: (state) =>
          state.payments.all !== null ? state.payments.all : false,
      }),
      newCardButtonText() {
        return this.addNewCard ? 'Hide form' : 'Add new payment method'
      },
    },
    watch: {
      role() {
        this.fetchPaymentMethods()
      },
    },
  }
</script>
