<template lang="html">
  <div class="CreditCard">
    <div class="CreditCard__brand">
      <div class="CreditCard__logo">
        <svg-icon :icon="card.brand" />
      </div>
      <div class="CreditCard__default" v-if="card.default">
        <span class="tag tag--payment">Default</span>
      </div>
      <div class="CreditCard__default" v-else>
        <button class="btn btn--tag" @click="makeDefault">Make default</button>
      </div>
      <div class="CreditCard__options clickable" @click="removeCard">
        <svg-icon icon="rubbish-bin"></svg-icon>
      </div>
    </div>
    <div class="CreditCard__number">
      <span>**** **** ****</span> {{ card.last_four }}
    </div>
    <div class="CreditCard__details">
      <div class="CreditCard__detail">
        <div class="title-6 text-semi text-white">Card holder name</div>
        <div class="text-xlg text-reg">{{ card.holder }}</div>
      </div>
      <div class="CreditCard__detail">
        <div class="title-6 text-semi text-white">Expiry</div>
        <div class="text-xlg text-reg">
          {{ card.exp_month }}/{{ card.exp_year }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CreditCard',
    props: {
      card: {
        type: Object,
        required: true,
      },
    },
    methods: {
      makeDefault() {
        this.$store.dispatch('payments/SET_DEFAUT_METHOD', this.card.id)
      },
      removeCard() {
        this.$store.dispatch('payments/DELETE_METHOD', this.card.id)
      },
    },
  }
</script>
