<template lang="html">
  <ValidationObserver v-slot="{ handleSubmit }" slim v-if="user">
    <form @submit.prevent="handleSubmit(handleProfileUpdate)" class="Form">
      <formly-form
        :form="form"
        :model="profile"
        :fields="fields"
        tag="div"
        class="Form__group"
      ></formly-form>
      <button class="btn btn--outlined">Update account</button>
    </form>
  </ValidationObserver>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'UpdateProfile',
    data: function() {
      return {
        profile: {
          photo: '',
          name: null,
          username: null,
          email: null,
          phone: null,
        },
        form: {},
        fields: [
          {
            key: 'photo',
            type: 'profilePicture',
            rules: 'size:3072',
            templateOptions: {
              type: 'file',
              label: 'Update profile picture',
            },
          },
          {
            key: 'name',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'text',
              label: 'Full name',
              layout: 'full',
            },
          },
          {
            key: 'username',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'text',
              label: 'Username',
              layout: 'full',
            },
          },
          {
            key: 'email',
            type: 'input',
            rules: 'email',
            templateOptions: {
              type: 'email',
              label: 'Email address',
              layout: 'full',
            },
          },
          {
            key: 'phone',
            type: 'input',
            rules: 'phoneNumber',
            templateOptions: {
              type: 'phone',
              label: 'Phone number',
              layout: 'full',
            },
          },
        ],
      }
    },
    methods: {
      handleProfileUpdate() {
        this.$store.dispatch('UPDATE_PROFILE', this.profile)
      },
      setInitialData() {
        this.profile.photo = this.user.photo_url
        this.profile.name = this.user.name
        this.profile.username = this.user.username
        this.profile.email = this.user.email
        this.profile.phone = this.user.phone
      },
    },
    created() {
      if (this.user && this.user.id) {
        this.setInitialData()
      }
    },
    computed: {
      ...mapState({
        user: (state) => state.user.profile,
        methods: (state) =>
          state.payments.all !== null ? state.payments.all : false,
      }),
    },
    watch: {
      user() {
        this.setInitialData()
      },
    },
  }
</script>
