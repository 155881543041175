<template lang="html">
  <div>
    <p>This information will appear on all of your receipts, and is a great place to add your full business name, VAT number, or address of record. Do not include any confidential or financial information such as credit card numbers.</p>
    <ValidationObserver v-slot="{ handleSubmit }" slim>
      <form @submit.prevent="handleSubmit(updateBilling)" class="Form">
        <formly-form :form="form" :model="info" :fields="fields" tag="div" class="Form__group"></formly-form>
        <button role="submit" class="btn btn--outlined">Update</button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "ExtraBillingInformation",
  props: ['default'],
  data: function () {
    return {
      info: {
        billing: '',
      },
      form: {},
      fields: [
        {
           key: 'billing',
           type: 'textarea',
           templateOptions: {
              label: 'Extra Billing Information',
              layout: 'full',
           },
        },
      ]
    }
  },
  created() {
    this.$store.dispatch('FETCH_EXTRA_BILLING')
  },
  methods: {
    setInitialData() {
      if (this.bill !== null) {
        this.info.billing = this.bill
      }
    },
    updateBilling() {
      this.$store.dispatch('UPDATE_EXTRA_BILLING', this.info.billing)
    }
  },
  computed: {
    ...mapState({
      bill: state => state.user.extra_billing_information,
    })
  },
  watch: {
    bill() {
      this.setInitialData()
    }
  }
}
</script>
