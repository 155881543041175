<template lang="html">
  <div class="dashboard__section">
    <section-title title="Manage divisions" />
    <fade-transition mode="out-in">
      <div class="divisions" v-if='divisions && divisions.length > 0'>
        <article v-for="division in divisions" :key='division.id' class="division">
          <div class="division__header">
            <h1 class="title-5 mr-16">{{ division.name }}</h1>
            <action icon="rubbish-bin" @actionClicked="removeDivision(division.id)"></action>
            <action icon="edit" @actionClicked="editDivision(division)"></action>
          </div>
          <div v-if="division.year_eligible" class="text-sm">
            Age Eligible: <br> {{division.year_eligible}}
          </div>
          <div>{{ division.description }}</div>
        </article>
      </div>
      <empty-message v-else>
        No divisions found!
      </empty-message>
    </fade-transition>

    <button class="btn btn--sm btn--outlined" @click="toggleForm">{{newDivisionText}}</button>
    <vue-toggle-slide :active="addNewDivision" class="pt-24">
      <div class="Card Card--limited" >
        <DivisionForm :division="editable"/>
      </div>
    </vue-toggle-slide>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DivisionForm from "@/components/Forms/DivisionForm";

export default {
  name: "DivisionsManagement",
  components: {
    DivisionForm
  },
  data: function() {
    return {
      addNewDivision: false,
      editable: false
    };
  },
  mounted() {
    this.$store.dispatch("GET_DIVISIONS");
  },
  methods: {
    removeDivision(id) {
      this.$store.dispatch("REMOVE_DIVISION", id);
    },
    editDivision(item) {
      this.editable = item;
      this.addNewDivision = true;
    },
    toggleForm() {
      this.addNewDivision = !this.addNewDivision;
      this.editable = false;
    }
  },
  computed: {
    ...mapState({
      divisions: state => (state.user ? state.user.divisions : false)
    }),
    newDivisionText() {
      return this.addNewDivision ? "Hide form" : "Add new division";
    }
  }
};
</script>

<style lang="scss">
@import "../../../styles/abstracts/include";
.divisions {
  display: flex;
  margin: 0 -5px 20px;
}
.division {
  background-color: color(bg, 5);
  padding: 20px 25px;
  max-width: 380px;
  border-radius: 5px;
  margin: 5px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
