<template lang="html">
  <ValidationObserver v-slot="{ handleSubmit }" slim v-if="team">
    <form @submit.prevent="handleSubmit(handleProfileUpdate)" class="Form">
      <formly-form :form="form" :model="team_form" :fields="fields" tag="div" class="Form__group"></formly-form>
      <button class="btn btn--outlined">Update Club Details</button>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "UpdateTeam",
  data: function() {
    return {
      team_form: {
        team_photo: "",
        team_name: ""
      },
      form: {},
      fields: [
        {
          key: "team_photo",
          type: "profilePicture",
          rules: "size:3072",
          templateOptions: {
            type: "file",
            label: "Update team picture"
          }
        },
        {
          key: "team_name",
          type: "input",
          rules: "required",
          templateOptions: {
            type: "text",
            label: "Team name",
            layout: "full"
          }
        }
      ]
    };
  },
  methods: {
    handleProfileUpdate() {
      this.$store.dispatch("UPDATE_TEAM", this.team_form);
    },
    setInitialData() {
      this.team_form.team_photo = this.team.photo_url;
      this.team_form.team_name = this.team.name;
    }
  },
  created() {
    if (this.team && this.team.id) {
      this.setInitialData();
    }
  },
  computed: {
    ...mapState({
      team: state => (state.user ? state.user.team : null)
    })
  },
  watch: {
    team() {
      this.setInitialData();
    }
  }
};
</script>
