<template lang="html">
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(handleSubmission)" class="Form">
      <div class="Form__wrapper">
        <formly-form :form="form" :model="model" :fields="fields" tag="div" class="Form__group"></formly-form>
      </div>
      <button class="btn btn--outlined">Update Details</button>
   </form>
 </ValidationObserver>
</template>

<script>
import Time from "@/helpers/TimeHelper";
import api from "@/api";

export default {
  name: "UpdatePlayerDetails",
  props: {
    register: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      model: {
        birthday: null,
        competition_level: null,
        special_considerations: null,
        emergency_contact_name: null,
        emergency_contact_number: null
      },
      form: {},
      fields: [
        {
          key: "birthday",
          type: "datepicker",
          rules: "required",
          templateOptions: {
            type: "date",
            format: "D/MM/YYYY",
            defaultPanel: "year",
            defaultValue: Time.dateYearsFromNow(-4),
            disableDatesMode: "birthday",
            label: "Date of birth",
            layout: "half"
          }
        },
        {
          key: "competition_level",
          type: "textarea",
          rules: "required",
          templateOptions: {
            label: "Highest level of play & year played",
            layout: "full"
          }
        },
        {
          key: "special_considerations",
          type: "textarea",
          rules: "",
          templateOptions: {
            label: "Special considerations",
            layout: "full"
          }
        },
        {
          key: "emergency_contact_name",
          type: "input",
          rules: "required",
          templateOptions: {
            type: "text",
            label: "Emergency Contact Name",
            layout: "half"
          }
        },
        {
          key: "emergency_contact_number",
          type: "input",
          rules: "phoneNumber|required",
          templateOptions: {
            label: "Emergency Contact Number",
            layout: "half"
          }
        }
      ]
    };
  },
  mounted() {
    api.http.get("profile/additional-information").then(res => {
      this.model = { ...res.body };
    });
  },
  methods: {
    handleSubmission() {
      if (!this.$store.state.status.isVisible) {
        this.$store.dispatch("status/TOGGLE_LOADER", {
          visible: true,
          status: "Updating information..."
        });
        api.http
          .post("profile/additional-information", { ...this.model })
          .then(res => {
            this.$store.dispatch("status/TOGGLE_LOADER", { visible: false });
            this.model = { ...res.body };
            this.$notify({
              group: "general",
              title: "Account information updated!"
            });
          });
      }
    }
  }
};
</script>

<style lang="css" scoped>
</style>
