<template lang="html">
  <div class="dashboard__section">
    <section-title title="Remove account" />
    <p v-if="userIs('admin')">
      Removing your account will remove all events, selectors and submissions
      associated with your Club Account.
    </p>

    <p v-if="userIs('player')">
      Removing your account will remove all of your data.
    </p>

    <p v-if="!userIs('player') && !userIs('admin')">
      Removing your account will remove all notes and reviews you've made.
    </p>

    <button class="btn btn--outlined" @click="removeAccount">
      Remove my account
    </button>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'RemoveAccount',
    data: function() {
      return {
        addNewDivision: false,
        editable: false,
      }
    },
    mounted() {
      this.$store.dispatch('GET_DIVISIONS')
    },
    methods: {
      removeAccount() {
        if (confirm('Are you sure you want to remove your account?')) {
          this.$store.dispatch('REMOVE_USER').then(() => {
            setTimeout(() => {
              this.$router.push('/')
            }, 500)
          })
        }
      },
    },
    computed: {
      ...mapState({
        user: (state) => state.user,
      }),
    },
  }
</script>

<style lang="scss"></style>
