<template lang="html">
  <ValidationObserver v-slot="{ handleSubmit }" slim >
    <form @submit.prevent="handleSubmit(handlePositionPreferenceUpdate)" class="Form">
      <formly-form :form="form" :model="profile" :fields="fields" tag="div" class="Form__group"></formly-form>
      <button class="btn btn--outlined">Update preffered positions</button>
    </form>
  </ValidationObserver>
</template>

<script>
import api from '@/api';
export default {
  name: 'UpdatePreferredPositions',
  data: function () {
    return {
      profile: {
        position: {},
        secondary_position: {},
      },
      form: {},
      fields: [
        {
           key: 'position',
           type: 'dropdownPrefferedPositions',
           rules: 'required',
           templateOptions: {
              label: 'Preffered position',
              layout: 'full',
              connected_keys: ['position', 'secondary_position'],
           },
        },
        {
           key: 'secondary_position',
           type: 'dropdownPrefferedPositions',
           rules: 'required',
           templateOptions: {
              label: 'Secondary position',
              layout: 'full',
              connected_keys: ['position', 'secondary_position'],
           },
        },
       ],
    }
  },
  mounted() {
    api.http.get('profile/positions')
    .then(res => {
      this.setPositions(res.body)
    })
  },
  methods: {
    setPositions(data) {
      this.profile.position = data[0];
      this.profile.secondary_position = data[1];
    },

    handlePositionPreferenceUpdate() {
      if (!this.$store.state.status.isVisible) {
        this.$store.dispatch('status/TOGGLE_LOADER', {visible: true, status: 'Updating preferences...'})
        api.http.post('profile/positions', {positions: [this.profile.position, this.profile.secondary_position]})
        .then(res => {
          this.$store.dispatch('status/TOGGLE_LOADER', {visible: false})
          this.setPositions(res.body);
          this.$notify({
            group: 'general',
            title: 'Preferred positions updated!',
          });
        })
      }
    },
  },
}
</script>
